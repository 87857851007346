import React from "react";
import '../assets/css/templates/news-template.css';
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import { ContextWithNewsItemProps } from "../types/newsItem";
import { Link } from "gatsby";
import ReactGA from 'react-ga';

const NewsTemplate: React.FC<ContextWithNewsItemProps> = ( { pageContext: {id, title, timestamp, mainImageName } }) => {
  const data = require(`../data/newsDataContent/${id}.html`).default;
  
   React.useEffect(() => {
  		ReactGA.initialize('UA-88504612-1');
  		ReactGA.pageview(`/news/${id}`);
   }, []);
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Pixel Works Software: ${title}`}</title>
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.NEWS} />
      <div className="gradient"></div>
      <main className="news-template__container">
        <Link className="apps-template__back-button" to="/news">⇦ BACK</Link>
        <div className="news-template__top">
          <h4 className="news-template__name">{title}</h4>
          <p className="news-item__timestamp">{timestamp}</p>
        </div>
        <div className="news-template__description-container">
          <img className="news-item__main-image" src={require(`../assets/images/${mainImageName}`).default} alt="asd"/>
          <div className="news-template__description-content" dangerouslySetInnerHTML={{__html: data}}></div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default NewsTemplate;